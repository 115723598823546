<template>
  <eden-confirm-dialog
    :title="`Mark as ${formatText(markFunction.replace('ing', 'ed'))}`"
    :button-text="'Mark'"
    :button-type="'success'"
    :show.sync="shouldShow"
    :close-on-click-modal="false"
    @confirm="markOrders"
    @cancel="unmarkOrders"
  >
    <p>
      Are you sure you want to mark selected orders as
      <span class="text-grey-primary text-bold">{{
        formatText(markFunction.replace("ing", "ed"))
      }}</span>
      ?
    </p>
  </eden-confirm-dialog>
</template>

<script>
import ofa from "@/requests/stewards/ofa";

export default {
  name: "MarkOrders",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    servicePartnerId: {
      type: [String, Number],
      default: "",
    },
    markFunction: {
      type: String,
      default: "pack",
    },
    orders: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    userId() {
      return this.$store.getters.user.logged_in_userId;
    },
  },
  methods: {
    markOrders() {
      const payload = {
        ofa_id: this.userId,
        order_info: this.orders,
        order_status: this.markFunction.replace("ing", "ed"),
      };

      ofa
        .mark(payload)
        .then((response) => {
          if (response.data.status) {
            this.$emit("mark");
            this.$message.success(response.data.message);
            this.shouldShow = false;
          }
        })
        .catch(() => {
          this.markFunction === "packing"
            ? (this.markAsPacked = false)
            : (this.markAsDispatched = false);
        });
    },
    unmarkOrders() {
      this.$emit("unmark");
      this.shouldShow = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
