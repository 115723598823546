<template>
  <el-table
    :data="orders"
    @select-all="setSelectedOrders"
    @selection-change="setSelectedOrders"
  >
    <el-table-column
      v-if="allowMarking || allowAccessFor(['superadmin', 'admin', 'operations'])"
      type="selection"
      width="45"
    ></el-table-column>
    <el-table-column v-if="!allowMarking" width="80">
      <template slot="header">
        <div class="table--header">
          <span>Order Id</span>
        </div>
      </template>
      <template slot-scope="scope">
        <router-link
          :to="{
            name: 'orders.order',
            params: { id: scope.row.order_id },
          }"
        >
          <span class="font-sm text-primary">
            {{ scope.row.order_id }}</span
          ></router-link
        >
      </template>
    </el-table-column>
    <el-table-column width="200">
      <template slot="header">
        <div class="table--header">
          <span>Customer</span>
        </div>
      </template>
      <template slot-scope="scope">
        <span class="font-sm"> {{ formatName(scope.row.customer) }}</span>
        <span class="font-xsm text-grey-tertiary">
          {{ scope.row.gardener }}</span
        >
      </template>
    </el-table-column>
    <el-table-column width="350">
      <template slot="header">
        <div class="table--header">
          <span>Meals</span>
        </div>
      </template>
      <template slot-scope="scope">
        <ul class="meals">
          <li v-for="(meal, i) in scope.row.meals" :key="i">
            {{ formatMealName(meal) }}
          </li>
        </ul>
      </template>
    </el-table-column>
    <el-table-column prop="name" width="250">
      <template slot="header">
        <span> Menu Type</span>
      </template>
      <template slot-scope="scope">
        <div
          v-if="
            scope.row.menu_type.toLowerCase().includes('corporate standard')
          "
        >
          Corporate Standard
        </div>
        <div
          v-else-if="
            scope.row.menu_type.toLowerCase().includes('individual standard')
          "
        >
          Individual Standard
        </div>
        <div v-else-if="scope.row.menu_type.toLowerCase().includes('lite')">
          Lite
        </div>
        <div v-else-if="scope.row.menu_type.toLowerCase().includes('basic')">
          Basic
        </div>
      </template>
    </el-table-column>
    <el-table-column width="150">
      <template slot="header">
        <div class="table--header">
          <span>Order Type</span>
        </div>
      </template>
      <template slot-scope="scope">
        <span class="font-sm">
          {{ formatText(scope.row.order_type) }}
        </span>
      </template>
    </el-table-column>
    <el-table-column width="150">
      <template slot="header">
        <div class="table--header">
          <span>Phone number</span>
        </div>
      </template>
      <template slot-scope="scope">
        <span class="font-sm">
          {{ formatPhoneNumber(scope.row.customer_phone_number) }}
        </span>
      </template>
    </el-table-column>
    <el-table-column width="150">
      <template slot="header">
        <div class="table--header">
          <span>Channel</span>
        </div>
      </template>
      <template slot-scope="scope">
        <span class="font-sm">
          {{ formatChannel(scope.row.channel_id) }}
        </span>
      </template>
    </el-table-column>
    <el-table-column width="150">
      <template slot="header">
        <div class="table--header">
          <span>Location area</span>
        </div>
      </template>
      <template slot-scope="scope">
        <span class="font-sm">
          {{ scope.row.location_area }}
          <span v-if="scope.row.location_areas"
            >({{ scope.row.priority }})</span
          >
        </span>
      </template>
    </el-table-column>
    <el-table-column width="300">
      <template slot="header">
        <div class="table--header">
          <span>Address</span>
        </div>
      </template>
      <template slot-scope="scope">
        <span class="font-sm"> {{ scope.row.customer_address }}</span>
      </template>
    </el-table-column>
    <el-table-column width="160">
      <template slot="header">
        <div class="table--header">
          <span>Ordered At</span>
        </div>
      </template>
      <template slot-scope="scope">
        <el-tag type="success" class="text-normalize">
          {{ formatDateAndTime(scope.row.created_at, "do m,y") }}
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column width="160">
      <template slot="header">
        <div class="table--header">
          <span>Packing status</span>
        </div>
      </template>
      <template slot-scope="scope">
        <order-function-status
          :period="period"
          :allow-marking="allowMarking"
          :mark-function="'packing'"
          :function-status="!!scope.row.packed"
          :function-time="scope.row.packed_at"
          :function-actor="scope.row.packed_by"
          @action="setSelectedOrder(scope.row, 'packing')"
        />
      </template>
    </el-table-column>
    <el-table-column width="160">
      <template slot="header">
        <div class="table--header">
          <span>Dispatching status</span>
        </div>
      </template>
      <template slot-scope="scope">
        <order-function-status
          :period="period"
          :allow-marking="allowMarking"
          :mark-function="'dispatching'"
          :function-status="!!scope.row.dispatched"
          :function-time="scope.row.dispatched_at"
          :function-actor="scope.row.dispatched_by"
          @action="setSelectedOrder(scope.row, 'dispatching')"
        />
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import OrderFunctionStatus from "@/components/Stewards/OrderFunctionStatus";

export default {
  name: "OrdersScheduleMealTable",
  props: {
    period: {
      type: String,
      default: "",
    },
    orders: {
      type: Array,
      default() {
        return [];
      },
    },
    allowMarking: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    OrderFunctionStatus,
  },
  data() {
    return {};
  },
  methods: {
    setSelectedOrders(orders) {
      this.$emit("set-selected-orders", orders);
    },
    setSelectedOrder(order, action) {
      this.$emit("set-selected-order", { order, action });
    },

    formatChannel(channel_id) {
      let name;
      switch (channel_id) {
        case 1:
          name = "Mobile app";
          break;

        case 2:
          name = "Green house";
          break;

        case 3:
          name = "Web app";
          break;

        default:
          name = "--";
      }
      return name;
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  text-align: left;

  .marker {
    display: block;
    font-size: 0.75rem;
    line-height: 16px;
    color: #4b6358;
    margin-left: 13px;
    margin-top: 5px;
    font-weight: 300;
  }
}

.meals {
  margin: 0;
  padding-left: 10px;
  list-style-type: circle;
  word-break: break-word;

  li {
    max-width: 250px;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  span {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.el-tag {
  cursor: pointer;
}
</style>
