<template>
  <div>
    <eden-table-actions :title="title" @search="searchOrders">
      <template slot="title">
        <eden-periods class="ml-10" :allow-all="true" :loading="loading" :default-period="'today'" @set-period="getOrders"
          addCustomFilter />
      </template>
      <template slot="actions">
        <template v-if="selectedOrders.length && showOrderActions">
          <el-button type="plain" icon="eden-icon-upload" class="mr-10" :disabled="loading || !pageData.length"
            @click="exportOrders">
            Export to Woodelivery
          </el-button>
          <el-switch v-model="markAsPacked" :disabled="!markableForPacking" active-text="Mark as packed"
            :active-value="true" inactive-text="">
          </el-switch>
          <el-switch v-model="markAsDispatched" :disabled="!markableForDispatch" active-text="Mark as dispatched"
            :active-value="true" inactive-text="" class="ml-10">
          </el-switch>
        </template>
        <template v-else>
          <eden-service-partners :loading="loading" :allow-all="true" :service-partner-id.sync="servicePartnerId"
            :service-partner-name.sync="servicePartnerName" :service="'meal'" />
          <el-button v-if="servicePartnerName !== 'all'" type="plain" icon="eden-icon-upload" class="ml-10"
            :disabled="loading || !pageData.length" @click="showExport = true">
            Export
          </el-button>
          <el-button type="plain" @click="viewHistory">
            View history
          </el-button>
        </template>
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <template v-if="pageData.length">
        <orders-schedule-meal-table :period="period" :allow-marking="allowMarking" :orders="pageData"
          @set-selected-orders="setSelectedOrders" @set-selected-order="setSelectedOrder" />
        <eden-pagination v-if="showPagination" :from="from" :to="to" :total="total" :current-page.sync="page" />
      </template>
      <eden-content-empty v-else :text="'No data'" />
    </template>
    <mark-orders ref="markOrders" :show.sync="showMarkOrders" :service-partner-id="servicePartnerId"
      :mark-function="markFunction" :orders="selectedOrders" @mark="updateOrders" @unmark="unmarkOrders" />
    <orders-schedule-meal-export :show.sync="showExport" :period="period" :custom-date="custom" :data="exportData"
      :data-time="pageDataTime" :service-partner-id="servicePartnerId" />
  </div>
</template>

<script>
import MarkOrders from "@/components/Stewards/OFA/MarkOrders";
import OrdersScheduleMealExport from "@/components/Orders/Schedule/Meal/OrdersScheduleMealExport.vue";

import schedule from "@/requests/orders/schedule";
import * as actions from "@/store/action-types";
import * as mutations from "@/store/mutation-types";

import { formatMealData } from "@/components/Orders/Schedule/format-schedule-data";
import OrdersScheduleMealTable from "@/components/Orders/Schedule/Meal/OrderScheduleMealTable";

export default {
  name: "OrdersScheduleMeal",
  props: {
    allowMarking: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    OrdersScheduleMealTable,
    MarkOrders,
    OrdersScheduleMealExport,
  },
  data() {
    return {
      loading: false,
      page: 1,
      pageData: [],
      showPagination: true,
      searchQuery: "",
      selectedOrders: [],
      showOrderActions: false,
      period: "today",
      servicePartnerId: "all",
      servicePartnerName: "",
      markAsPacked: false,
      markableForPacking: true,
      markAsDispatched: false,
      markableForDispatch: true,
      markFunction: "",
      showMarkOrders: false,
      exportData: [],
      showExport: false,
      pageDataTime: "",
      custom: {
        from: null,
        to: null,
      },
    };
  },
  computed: {
    orders() {
      return this.$store.getters.orders_schedule.meal;
    },
    from() {
      return this.orders.pages[this.page].from;
    },
    to() {
      return this.orders.pages[this.page].to;
    },
    total() {
      return this.orders.total;
    },
    title() {
      const total = this.total;
      const result = this.pageData.length;
      const length = this.showPagination ? total || 0 : result;
      return `${length} Order${length > 1 ? "s" : ""}`;
    },
  },
  watch: {
    page() {
      const pageFetched = !!this.orders.pages[this.page];
      if (!pageFetched) {
        this.getOrders({
          period: this.period,
          from: this.custom.from,
          to: this.custom.to,
        });
      }
    },
    servicePartnerId() {
      this.getOrders({
        period: this.period,
        from: this.custom.from,
        to: this.custom.to,
      });
    },
    searchQuery() {
      if (this.searchQuery !== "") {
        this.searchOrders();
      } else {
        this.setPageData();
      }
    },
    markAsPacked() {
      if (this.markAsPacked) {
        this.markFunction = "packing";
        this.showMarkOrders = true;
      }
    },
    markAsDispatched() {
      if (this.markAsDispatched) {
        this.markFunction = "dispatching";
        this.showMarkOrders = true;
      }
    },
  },
  created() {
    this.getOrders({
      period: this.period,
    });
  },
  methods: {
    exportOrders() {
      this.loading = true;
      const orders = this.selectedOrders.map(order => Object.keys(order)[0]);
      const payload = {
        "order_ids": orders
      }
      schedule
        .logisticsOrders(payload)
        .then((response) => {
          console.log(response)
          this.$message.success(response.data.message)
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    getOrders({ period, from, to }) {
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });
      this.custom.from = start_date;
      this.custom.to = end_date;
      this.loading = true;
      this.$store
        .dispatch(actions.GET_ORDERS_SCHEDULE, {
          service: "meal",
          servicePartnerId: this.servicePartnerId,
          start_date: this.custom.from,
          end_date: this.custom.to,
          page: this.page,
        })
        .then(() => {
          this.mixpanelTrack({
            event: this.allowMarking
              ? `order_fulfillment_filter_${period}`
              : `order_schedule_meal_${period}`,
          });
          this.period = period;
          this.setPageData();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },

    searchOrders(query) {
      this.loading = true;
      if (!query) {
        this.loading = false;
        this.setPageData();
        return;
      }
      const start_date = this.custom.from;
      const end_date = this.custom.to;
      schedule
        .search({
          servicePartnerId: this.servicePartnerId,
          service: "meal",
          query,
          start_date,
          end_date,
        })
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.pageData = data ? formatMealData(data) : [];
            this.exportData = this.pageData;
            this.showPagination = false;
          } else {
            this.$message.info("No customer order");
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },

    setPageData() {
      this.pageData = this.orders?.pages[this.page]?.data;
      this.pageData = formatMealData(this.pageData);
      this.showPagination = true;
      this.pageDataTime = new Date();
    },

    setSearchQuery(query) {
      this.searchQuery = query;
    },

    setSelectedOrders(orders) {
      this.selectedOrders = [];
      this.showOrderActions = false;
      this.markableForPacking = true;
      this.markableForDispatch = true;
      orders.forEach((order) => {
        this.markableForPacking = this.markableForPacking && !order.packed;
        this.markableForDispatch =
          this.markableForDispatch && order.packed && !order.dispatched;
        let payload = {};
        payload[order.order_id] = {
          is_meal: !this.servicePartnerName.toLowerCase().includes("juice"),
        };
        this.selectedOrders.push(payload);
        this.showOrderActions = true;
      });
    },

    setSelectedOrder({ order, action }) {
      this.selectedOrders = [];
      let payload = {};
      payload[order.order_id] = {
        is_meal: !this.servicePartnerName.toLowerCase().includes("juice"),
      };
      this.selectedOrders.push(payload);

      switch (action) {
        case "packing":
          this.markFunction = "packing";
          this.markSelectedOrder("packing");
          break;
        case "dispatching":
          this.markFunction = "dispatching";
          order.packed
            ? this.markSelectedOrder("dispatching")
            : this.$message.error("You have to pack this order");
          break;
        default:
          break;
      }
    },

    markSelectedOrder(action) {
      this.mixpanelTrack({
        event: `order_fulfillment_single_${action}_mark`,
      });
      this.$nextTick(() => {
        this.$refs.markOrders.markOrders();
      });
    },
    updateOrders() {
      this.loading = true;
      const markFunction = this.markFunction.replace("ing", "ed");
      const ordersToMark = this.selectedOrders.map((order) => {
        return parseInt(Object.keys(order).toString());
      });
      const name = this.$store.getters.user.full_name;
      const time = new Date();

      this.pageData.forEach((order, index) => {
        if (ordersToMark.includes(order.order_id)) {
          this.pageData[index][`${markFunction}`] = true;
          this.pageData[index][`${markFunction}_at`] = time;
          this.pageData[index][`${markFunction}_by`] = name;
        }
      });

      this.mixpanelTrack({
        event: `order_fulfillment_multiple_${markFunction}_mark`,
      });

      setTimeout(() => {
        this.updateOrdersInStore();
        this.selectedOrders = [];
        this.showOrderActions = false;
        this.loading = false;
        this.unmarkOrders();
      }, 200);
    },

    updateOrdersInStore() {
      const data = JSON.parse(JSON.stringify(this.pageData));
      this.$store.commit(mutations.ORDERS_SCHEDULE, {
        service: "meal",
        sp: this.servicePartnerId,
        period: this.period,
        page: this.page,
        data: {
          from: this.from,
          to: this.to,
          data,
        },
      });
    },

    unmarkOrders() {
      this.markFunction === "packing"
        ? (this.markAsPacked = false)
        : (this.markAsDispatched = false);
    },

    viewHistory() {
      this.$router.push({ name: "order-fulfillment.history" });
      this.mixpanelTrack({
        event: `order_fulfillment_view_history`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  text-align: left;

  .marker {
    display: block;
    font-size: 0.75rem;
    line-height: 16px;
    color: #4b6358;
    margin-left: 13px;
    margin-top: 5px;
    font-weight: 300;
  }
}

.meals {
  margin: 0;
  padding-left: 10px;
  list-style-type: circle;
  word-break: break-word;

  li {
    max-width: 250px;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  span {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.el-tag {
  cursor: pointer;
}
</style>
