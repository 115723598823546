import { render, staticRenderFns } from "./MarkOrders.vue?vue&type=template&id=25332551&scoped=true&"
import script from "./MarkOrders.vue?vue&type=script&lang=js&"
export * from "./MarkOrders.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25332551",
  null
  
)

export default component.exports